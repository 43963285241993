// src/components/Navbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

/**
 * `Navbar` es un componente de React que representa una barra de navegación con soporte para menús móviles.
 * El componente incluye un logotipo, enlaces de navegación, y un botón para solicitar un crédito. También tiene un menú desplegable para dispositivos móviles que se puede abrir y cerrar.
 *
 * Estado:
 * - `isMobileMenuOpen` (boolean): Indica si el menú móvil está abierto o cerrado.
 *
 * Métodos:
 * - `toggleMobileMenu`: Alterna el estado del menú móvil entre abierto y cerrado.
 *
 * Ejemplo de uso:
 * ```jsx
 * <Navbar />
 * ```
 *
 * Estilos:
 * Los estilos para este componente están definidos en el archivo `Navbar.css`.
 *
 * @component
 */

const Navbar = () => {
  const linkStyle = {
    color: "inherit",
    textDecoration: "none",
  };

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link style={linkStyle} to="/">
          <img src="./images/logo-edf.svg" alt="El de Fiar Logo" />
        </Link>
      </div>
      <div className="navbar-toggle" onClick={toggleMobileMenu}>
        <svg
          className="toggle-icon"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="100"
          height="100"
          viewBox="0 0 50 50"
        >
          <path d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z"></path>
        </svg>
      </div>
      <ul
        className={`navbar-links ${
          isMobileMenuOpen ? "mobile-menu-active" : ""
        }`}
      >
        <li>
          <Link style={linkStyle} to="/">
            <p>
              <strong>INICIO</strong>
            </p>
          </Link>
        </li>
        <li>
          <Link style={linkStyle} to="/nosotros">
            <p>
              <strong>NOSOTROS</strong>
            </p>
          </Link>
        </li>
        <li>
          <a style={linkStyle} href="#contactForm">
            <p>
              <strong>CONTACTO</strong>
            </p>
          </a>
        </li>
      </ul>
      {/* <Link to="/request-credit" className="navbar-btn btn">
        Solicita Tu Crédito
      </Link> */}
      <a href="#contactForm" className="navbar-btn btn"> Solicita Tu Crédito</a>
      {isMobileMenuOpen && (
        <div className="mobile-menu">
          <ul className="mobile-links">
            <li>
              <Link style={linkStyle} to="/">
                INICIO
              </Link>
            </li>
            <li>
              <Link style={linkStyle} to="/nosotros">
                NOSOTROS
              </Link>
            </li>
            <li>
              <a style={linkStyle} href="#contactForm">
                <p>
                  <strong>CONTACTO</strong>
                </p>
              </a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
