import React, { useEffect } from "react";
import "./ContactForm.css";
import emailjs from 'emailjs-com';

const SERVICE_ID = "service_a2cbb1p";
const TEMPLATE_ID = "template_233xqbp";
const PUBLIC_KEY = "_Y9DU6sng0tYqrLNC";

function ContactForm() {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
      (result) => {
        console.log(result.text);
        alert("Message Sent Successfully");
      },
      (error) => {
        console.log(error.text);
        alert("Something went wrong!");
      }
    );
    e.target.reset();
  };

  useEffect(() => {
    const loadMapScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBKp-vpw9i0NpGISnvZgE9n2uUtWs3obo8&callback=initMap&libraries=marker`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      window.initMap = () => {
        const torreQuadrata = { lat: 19.393124, lng: -99.239168 };

        const map = new window.google.maps.Map(document.getElementById("map"), {
          zoom: 15,
          center: torreQuadrata,
        });

        new window.google.maps.Marker({ position: torreQuadrata, map: map });
      };
    };

    loadMapScript();
  }, []);

  return (
    <div id="contactForm" className="contact-container">
      <div className="form-container">
        <form onSubmit={handleOnSubmit}>
            <input
              type="text"
              id="from_name"
              name="from_name"
              placeholder="Nombre Completo"
              required
            />

            <input
              type="email"
              id="from_email"
              name="from_email"
              placeholder="Correo Electrónico"
              required
            />

            <input
              type="tel"
              id="from_tel"
              name="from_tel"
              placeholder="Ej. 5512345678"
              required
            />

            <input
              type="number"
              id="from_monto"
              name="from_monto"
              placeholder="Monto Solicitado"
              required
            />

            <select id="tipo_credito" name="tipo_credito" required>
              <option value="">Seleccione el tipo de crédito</option>
              <option value="personal">Crédito Personal</option>
              <option value="hipotecario">Crédito Empresarial</option>
              <option value="automotriz">Crédito Automotriz</option>
            </select>
          <button type="submit" className="btn">
            Solicita Tu Crédito
          </button>
        </form>
      </div>
      <div id="map">{/* mapa */}</div>
    </div>
  );
}

export default ContactForm;
