import React from "react";
import "./Card.css";
import { Link } from "react-router-dom";

/**
 * `Card` es un componente de React que representa una tarjeta interactiva con una imagen, un título y un texto descriptivo.
 *
 * Este componente está diseñado para mostrar información visualmente atractiva y permite la navegación a una ruta específica cuando se hace clic en la tarjeta.
 *
 * Props:
 * - `image` (string): La URL de la imagen que se mostrará en la tarjeta.
 * - `title` (string): El título que se mostrará en la tarjeta.
 * - `text` (string): El texto descriptivo que se mostrará debajo del título.
 *
 * Ejemplo de uso:
 * ```jsx
 * <Card
 *   image="creditos-personales.png"
 *   title="Créditos Personales"
 *   text="Impulsa tu negocio con nuestros créditos empresariales. Obtén hasta 30 millones de pesos con aprobación en 48 horas."
 * />
 * ```
 *
 * Estilos:
 * Los estilos para este componente están definidos en el archivo `Card.css`.
 *
 * @component
 */

export default function Card({ link, image, title, text }) {
  const linkStyle = {
    color: "inherit",
    textDecoration: "none",
  };

  return (
    <div className="card">
      <Link style={linkStyle} to={`/${link}`}>
        <img src={image} alt="Créditos Empresariales" />
        <div className="card-info">
          <h4>{title}</h4>
          <p className="card-text">{text}</p>
        </div>
      </Link>
    </div>
  );
}
